










































































































import {formatNumber} from "@/common";
import {Component, Vue} from 'vue-property-decorator';
import {OrderGetByIdResult} from "@/models/order/OrderGetByIdResult";
import {ReturnService} from '@/views/order/ReturnService';
import {OptionOrderStatus} from '@/models/order/OrderSearchResult';
import moment from 'moment';
import OrderDetailList from "@/views/order/OrderDetailList.vue";

@Component({
    components: {
        OrderDetailList
    }
})

export default class OrderExchange extends Vue {
    private returnService = new ReturnService();

    constructor() {
        super();
    }

    order: OrderGetByIdResult = new OrderGetByIdResult();
    vatUnit: string = "";

    formatNumber(value: any) {
        return formatNumber(value);
    }

    async mounted() {
        await this.getData();
    }

    get chipColor() {
        return (value: any) => {
            return new OptionOrderStatus(value).color;
        }
    }

    get statusName() {
        return (value: any) => {
            return new OptionOrderStatus(value).name;
        }
    }

    private async getData(){
        let orderId = this.$route.params.id;

        try {
            this.$vs.loading();
            let result = await this.returnService.getOrderExchangeById(orderId);

            if (result.code > 0) {
                let datetimeFormat = 'DD-MM-YYYY HH:mm';
                let orderData = result.data as OrderGetByIdResult;

                if (orderData) {
                    orderData.orderDate = moment(orderData.orderDate).format(datetimeFormat);
                    orderData.createTime = moment(orderData.createTime).format(datetimeFormat);

                    if (orderData.deliveryDate) {
                        orderData.deliveryDate.from = moment(orderData.deliveryDate.from).format(datetimeFormat);
                        orderData.deliveryDate.to = moment(orderData.deliveryDate.to).format(datetimeFormat);
                    }
                }

                this.order = orderData;
            }

            this.$vs.loading.close();
        } catch (error) {
            this.$vs.loading.close();

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }
    }
}
